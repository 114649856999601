
import { modelStatuses } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import BarItem, { Item } from './item.vue'

@Component({
  components: {
    BarItem
  },
  name: 'ModelStatusBar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get itemIds () {
    return this.items.map(o => o.id)
  }

  get allItemsPinned () {
    return this.itemIds.every(o => this.overlayIdsPinned.includes(o))
  }

  get items (): Item[] {
    return [
      {
        color: modelStatuses.live.color,
        count: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.status === 'live').length,
        icon: '$custom-duotone-dot-circle',
        id: modelStatuses.live.id,
        title: modelStatuses.live.name,
        type: modelStatuses.live.type
      },
      {
        color: modelStatuses.future.color,
        count: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.status === 'future').length,
        icon: '$fas-spinner',
        id: modelStatuses.future.id,
        title: modelStatuses.future.name,
        type: modelStatuses.future.type
      },
      {
        color: modelStatuses.deprecated.color,
        count: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.status === 'deprecated').length,
        icon: '$fas-ban',
        id: modelStatuses.deprecated.id,
        title: modelStatuses.deprecated.name,
        type: modelStatuses.deprecated.type
      },
      {
        color: modelStatuses.removed.color,
        count: Object.values(this.currentDiagram?.objects || {}).filter(o => this.modelModule.objects[o.modelId]?.status === 'removed').length,
        icon: '$fas-times',
        id: modelStatuses.removed.id,
        title: modelStatuses.removed.name,
        type: modelStatuses.removed.type
      }
    ]
  }

  pinAll () {
    if (this.allItemsPinned) {
      this.$replaceQuery({
        overlay_pin: this.$removeQueryArray(...this.itemIds)
      })

      analytics.modelStatusUnpinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    } else {
      this.$replaceQuery({
        overlay_pin: this.$unionQueryArray(...this.itemIds)
      })

      analytics.modelStatusPinAll.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
